import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';

@Component({
  selector: 'aff-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;

  type: string = '';

  // set attributes
  @Input() set options(att) {
    this.type = att['type'];
    Object.keys(att).forEach(key => {
      this.inputElement.nativeElement.setAttribute(key, att[key]);
    });
  }
  
  @Input() class = '';
  @Input() disabled: boolean = false;
  @Input() error: string = '';

  @Output() onOutPutValue = new EventEmitter();

  greenBorder: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  onChange(inputValue) {
    this.green(inputValue);
    
    const options = {
      value: this.type === 'file' ? inputValue.target.files : inputValue.target.value,
      name: this.inputElement.nativeElement.name
    }
    this.onOutPutValue.emit(options);
    if (this.type === 'file') {
      inputValue.target.value = null;
    } 
  }

  green(event) {
    if(event.target.value.length) {
        this.greenBorder = true;
    } else {
      this.greenBorder = false;
    }
  }

}
