import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Status } from '@enums/index';
import { 
  GeneratedCommissionService, 
  HelperService, 
  PageService, 
  OrderService } from '@services/index';
import { IPage } from '@interfaces/index';
import { IState } from '@models/index';
import { GeneratedCommissionStatus, GeneratedCommissionComType } from '@enums/index';

@Component({
  selector: 'aff-generated-commissions-list-page',
  templateUrl: './generated-commissions-list-page.component.html',
  styleUrls: ['./generated-commissions-list-page.component.scss']
})
export class GeneratedCommissionsListPage implements OnInit {
  genCommissions$: Observable<IState>;
  rows = [];
  selectedRows = [];
  showSelection: boolean = false;
  loadingIndicator: boolean = true;
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  actionType: GeneratedCommissionStatus;
  
  orderId: string = '';
  approvePopup: boolean = false;

  orders$: Observable<any>;

  affiliateManagers$: Observable<IState>;
  private unsubscribe$ = new Subject<void>();
  
  constructor(private generatedCommissionService: GeneratedCommissionService,
              private pageService: PageService,
              private orderService: OrderService) { 
                // set page breadcrum
                pageService.changePageInfo({ breadcrum: ['Income Commissions']});
                
                this.genCommissions$ = generatedCommissionService.getGeneratedCommissionsSelector();
                generatedCommissionService.fetchGeneratedCommissions(1, { status: 'pending' });

                this.orders$ = orderService.getOrdersSelector();
              }

  ngOnInit() {
    this.orders$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(order => { 
        if (order.isUpdated) {
          this.generatedCommissionService.updateGeneratedCommissions(this.selectedRows);
          this.selectedRows = [];
          return this.approvePopup = false;
        }
        
        if (!HelperService.isObjectEmpty(order.createdItem)) {
          this.orderId = order.createdItem.id;
          this.approvePopup = true;
        }
      });

    this.genCommissions$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => { 
        if (!HelperService.isObjectEmpty(state.selectedItem)) {
          this.approvePopup = true;
        }

        if (state.isLoaded) {
          this.rows = [];
          for (const genCommission of state.data) {
            if(genCommission.commission_type === 'lead') {
              genCommission.sale_order_amount = '';
            }
            const row = {
              id: genCommission.id,
              dateCreated: HelperService.dateFormater(genCommission.created_at),
              commissionType: HelperService.getEnumValue(GeneratedCommissionComType, genCommission.commission_type),
              commission: genCommission.commission,
              affiliate: genCommission.user.email,
              userId: genCommission.user_id,
              campaign: genCommission.campaign.name,
              campaignStatus: genCommission.campaign ? Status[genCommission.campaign.status] : '',
              saleOrderId: genCommission.sale_order_id,
              saleOrderAmount: genCommission.sale_order_amount
            };
            this.rows.push(row);
          }
        }
        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
      });
  }

  onApproveToggle(row: any): void {
    this.selectedRows = [row];
    this.orderService.createOrder({ user_id: row.userId,  commissions: [row.id] });
  }

  onChangeMassStatus(): void {
    if (!this.selectedRows.length) return;
    let commissions: number[] = [];
    this.selectedRows.forEach(com => {
      if(commissions.indexOf(com.id) === -1){
        return commissions.push(+com.id)
      }
    })
    this.orderService.createOrder({ user_id: this.selectedRows[0].userId,  commissions });
  }

  getSelectionOptions(show: boolean): boolean {
    return this.showSelection = show;
  }

  onSelect(e): void {
    this.selectedRows = e.selected;
  }

  setPage(e): void {
    this.selectedRows = [];
    this.generatedCommissionService.fetchGeneratedCommissions(e.offset + 1, { status: 'pending' });
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}