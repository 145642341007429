import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import {
    fetchEventTypes,
    fetchEventTypesSuccess,
    fetchEventTypesFailure,
    getEventType,
    getEventTypeSuccess,
    getEventTypeFailure,
    addEventType,
    addEventTypeSuccess,
    addEventTypeFailure,
    updateEventType,
    updateEventTypeSuccess,
    updateEventTypeFailure,
    deleteEventType,
    deleteEventTypeSuccess,
    deleteEventTypeFailure,
    fetchClick,
    fetchClickSuccess,
    fetchClickFailure
} from "./tracking.action";
import { EMPTY, of } from "rxjs";
import { map, mergeMap, catchError, tap } from "rxjs/operators";
import { TrackingService } from "../../services/tracking/tracking.service";
import { SharedService } from '@services/shared/shared.service';


@Injectable()
export class EventTypeEffects {
  loadEventTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchEventTypes),
      mergeMap(action =>
        this.trackingService.getEventTypes(action.page, action.filterOptions).pipe( 
          map(res => {
            return fetchEventTypesSuccess({ eventType: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchEventTypesFailure({ error }));
          })
        )
      )
    )
  );

  getEventType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEventType),
      mergeMap(action => 
        this.trackingService.getEventType(action.id).pipe(
          map(res => {
            return getEventTypeSuccess({ eventType: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(getEventTypeFailure({ error }));
          })
        )
      )
    )
  );

  addEventType$ = createEffect(() =>
    this.actions$.pipe(
        ofType(addEventType),
        mergeMap(action =>
        this.trackingService.insertEventType(action.eventType).pipe(
            map(eventType => {
            return addEventTypeSuccess({ eventType });
            }),
            tap(res => {
            this.trackingService.fetchEventTypes(1, {}); 
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
            }),
            catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(addEventTypeFailure({ error }));
            })
        )
        )
    )
);

  updateEventType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEventType),
      mergeMap(action =>
        this.trackingService.renewEventType(action.eventType).pipe(
          map(eventType => {
            return updateEventTypeSuccess({ eventType });
          }),
          tap(res => {
            this.trackingService.fetchEventTypes(1, {}); 
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(updateEventTypeFailure({ error }));
          })
        )
      )
    )
  );

  deleteEventType$ = createEffect(() =>
  this.actions$.pipe(
    ofType(deleteEventType),
    mergeMap(action =>
      this.trackingService.removeEventType(action.id).pipe(
        map(() => {
          return deleteEventTypeSuccess({ id: action.id });
        }),
        tap(res => {
            this.trackingService.fetchEventTypes(1, {}); 
        }),
        catchError(error => {
          this.sharedService.onLoaderToggle(false);
          return of(deleteEventTypeFailure({ error }));
        })
      )
    )
  )
);

getClick$ = createEffect(() =>
this.actions$.pipe(
  ofType(fetchClick),
  mergeMap(action => 
    this.trackingService.getClick(action.page, action.id).pipe(
      map(res => {
        return fetchClickSuccess({ click: res });
      }),
      catchError(error => {
        return of(fetchClickFailure({ error }));
      })
    )
  )
)
);

  constructor(
    private actions$: Actions,
    private trackingService: TrackingService,
    private sharedService: SharedService,
    private router: Router
  ) {}
}
