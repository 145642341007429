/**
 * Test Environment Configuration
 */

export const environment = {
  production: false,
  site_key: '6Lc8PeMUAAAAAGrRryFEW2p9bmvxRNR1hn5_MDdA',
  backendUrl: 'https://affiliate-api-dev.webiz.ge'
  // backendUrl: 'http://localhost:7000' 
}
