import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { environment } from '../environments/environment';
import { JwtInterceptor, ErrorInterceptor } from './helpers/index';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserPageModule } from '@pages/index';
import { AuthorizedPagesModule } from '@pages/authorized-pages/authorized-pages.module';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  userReducer,
  campaingReducer,
  CampaignEffects,
  commissionGroupReducer,
  CommissionGroupEffects,
  bannerReducer,
  BannerEffects,
  affiliateReducer,
  AffiliateEffects,
  UserEffects,
  campaignAffiliatesReducer,
  CampaignAffiliatesEffects,
  sidebarReducer,
  AffiliateManagerssEffects,
  affiliateManagersReducer,
  componentsReducer,
  languageReducer,
  LanguageEffects,
  countryReducer,
  CountryEffects,
  pageReducer,
  PageEffects,
  navigationReducer,
  NavigationEffects,
  paymentReducer,
  PaymentEffects,
  generatedCommissionReducer,
  GeneratedCommissionEffects,
  paymentMethodReducer,
  PaymentMethodEffects,
  OrderEffects,
  orderReducer,
  messageReducer,
  MessageEffects,
  configReducer,
  ConfigEffects,
  ReportEffects,
  reportReducer,
  dashboardReducer,
  DashboardEffects,
  eventTypeReducer,
  EventTypeEffects,
  eventCategoryReducer,
  EventCategoryEffects,
  campaignUrlsReducers,
  CampaignUrlsEffects,
  notificationsReducer,
  NotificationsEffects
} from '@store/index';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AngularFontAwesomeModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AuthorizedPagesModule,
    UserPageModule,
    StoreModule.forRoot({
      user: userReducer,
      campaign: campaingReducer,
      commissionGroup: commissionGroupReducer,
      banner: bannerReducer,
      affiliate: affiliateReducer,
      campaignAffiliates: campaignAffiliatesReducer,
      sidebar: sidebarReducer,
      affiliateManagers: affiliateManagersReducer,
      components: componentsReducer,
      language: languageReducer,
      country: countryReducer,
      page: pageReducer,
      navigation: navigationReducer,
      payment: paymentReducer,
      generatedCommission: generatedCommissionReducer,
      paymentMethods: paymentMethodReducer,
      order: orderReducer,
      message: messageReducer,
      config: configReducer,
      report: reportReducer,
      dashboard: dashboardReducer,
      eventType: eventTypeReducer,
      eventCategory: eventCategoryReducer,
      campaignUrls: campaignUrlsReducers,
      notifications: notificationsReducer,
    }),
    EffectsModule.forRoot([
      CampaignEffects,
      CommissionGroupEffects,
      BannerEffects,
      AffiliateEffects,
      UserEffects,
      CampaignAffiliatesEffects,
      AffiliateManagerssEffects,
      LanguageEffects,
      CountryEffects,
      PageEffects,
      NavigationEffects,
      PaymentEffects,
      GeneratedCommissionEffects,
      PaymentMethodEffects,
      OrderEffects,
      MessageEffects,
      ConfigEffects,
      ReportEffects,
      DashboardEffects,
      EventTypeEffects,
      EventCategoryEffects,
      CampaignUrlsEffects,
      NotificationsEffects
    ]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
