import { createSelector, createFeatureSelector } from '@ngrx/store';
import { UserState } from '@models/index';

export const userSelector = createFeatureSelector<UserState>('user');
export const getUserSelector = createSelector(userSelector, (state: UserState) => state);
export const getProfileSelector = createSelector(userSelector, (state: UserState) => state.user);
export const getFiltersSelector = createSelector(userSelector, (state: UserState) => state.filters);
export const getSelectedFilterSelector = createSelector(userSelector, (state: UserState) => state.filters.selectedItem);
export const getAuthToken = createSelector(userSelector, (state: UserState) => state.authToken);
export const isUserAuthenticated =  createSelector(userSelector, (state: UserState) => !!state.authToken);
export const getUserLoginHistory = createSelector(userSelector, (state: UserState) => state.loginHistories);
