import { Component } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { UserService, HelperService } from "@services/index";
import { Router } from "@angular/router";

/**
 * User Profile Information Component
 */
@Component({
  selector: "aff-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent {
  profile$: Observable<any>;
  userName: string = "";
  userId: number;
  userImage: string;

  private unsubscribe$ = new Subject<void>();

  constructor(private userService: UserService, private router: Router) {
    this.profile$ = userService.getProfileSelector();
  }

  /**
   * @ignore
   */
  ngOnInit() {
    this.profile$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (HelperService.isObjectEmpty(user)) return;
      this.userName = user.profile.first_name + " " + user.profile.last_name;
      this.userId = user.id;
      this.userImage = user.image_url;
      
    });
  }

  onClickProfile() {
    console.log('clicked');
    
    this.router.navigate([`/affiliate-managers/affiliate-manager/${this.userId}`]);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
