import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { BannerService, PageService } from '@services/index';
import { IPage } from '@interfaces/index';
import { takeUntil } from 'rxjs/operators';
import { IModalvalue } from '@shared-models/index';

@Component({
  selector: 'aff-banners-list-page',
  templateUrl: './banners-list-page.component.html',
  styleUrls: ['./banners-list-page.component.scss']
})
export class BannersListPage implements OnInit, OnDestroy {

  bannerID = [];
  deleteConfirmation: boolean = false;
  deleteValues: string = '';
  banner$: Observable<any>;
  unsubscribe$ = new Subject<void>();

  showFiterComponent: boolean = false;
  loadingIndicator: boolean = true;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  rows: any[] = [];

  constructor(
    private router: Router,  
    private bannerService: BannerService,
    private pageService: PageService
    ) { 
      this.banner$ = bannerService.getBannersSelector(); 
      pageService.changePageInfo({ breadcrum: ['Promotion Tools']});
  }

  
  ngOnInit() {
    this.bannerService.fetchBanners(1, {});
    this.banner$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.rows = [];
          for (const banner of state.data) {
            const row = {
              id: banner.id,
              promotionToolName: banner.name,
              promotionToolType: banner.type, 
              destinationUrl: banner.destination_url ? banner.destination_url.url : '',
              campaign: banner.campaign.name,
              affiliateManager: banner.campaign.user ? banner.campaign.user.email : ''
            };
            this.rows.push(row);
          }
        }

        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
    });

  }
  
  onAddBanner() {
    this.router.navigate(['/promotion-tools/promotion-tool']);
  }
  
  setPage(e) {
    this.selectedRows = [];
    this.bannerService.fetchBanners(e.offset + 1, {});
  }

  onDeleteBanner() {
    if(this.selectedRows.length){
      this.deleteConfirmation = true;
      for (const selectedRow of this.selectedRows) {
        this.bannerID.push(selectedRow.id);
    }
  }
    this.deleteValues = '';
    this.selectedRows = [];

  }

   confirmationOption(options: IModalvalue){
     if (options.confirm) {
       this.bannerID.forEach(bannID=> {
        this.bannerService.deleteBanner(bannID);   
      }) 
     }
     this.bannerID = [];
     this.deleteConfirmation = false;
   
   }

  onSelect(e) {
    this.selectedRows = e.selected;
  }

  /**
   * Filter Handle
   */
  onChangeFilterHandle(e) {
  }

  
  onToggleFilter() {
    this.showFiterComponent = !this.showFiterComponent;
  }

  onAction(action: string, row: any) {
    switch (action) {
      case "delete":
        this.deleteConfirmation = true;
        this.deleteValues = `Promotion Tool: <br><br> ${row.promotionToolName}`;
        this.bannerID.push(row.id);
        break;

      case "edit":
        this.router.navigate([`/promotion-tools/promotion-tool/${row.id}`])
        break;

      default:
     
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
