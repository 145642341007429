import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PermissionGuard } from '@guards/index';
import { 
    AffiliateActivityPage,
    ClickPage,
    TopAffiliatesPage,
    TopCampaignsPage,
    TopPromotionToolsPage,
    TopReferringUrlsPage
} from './index';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'top-affiliates',
        pathMatch: 'full'
    },
    {
        path: 'top-affiliates',
        canActivate: [PermissionGuard],
        component: TopAffiliatesPage, 
        data: {
            breadcrumb: 'Top Affiliates'
        },
    },
    {
        path: 'top-campaigns',
        canActivate: [PermissionGuard],
        component: TopCampaignsPage,
        data: {
            breadcrumb: 'Top Campaigns'
        },
    },
    {
        path: 'top-promotion-tools',
        canActivate: [PermissionGuard],
        component: TopPromotionToolsPage,
        data: {
            breadcrumb: 'Top Promotion Tools'
        },
    },
    {
        path: 'top-referring-urls',
        canActivate: [PermissionGuard],
        component: TopReferringUrlsPage,
        data: {
            breadcrumb: 'Top Referring URLs'
        },
    },
    {
        path: 'affiliate-activity',
        canActivate: [PermissionGuard],
        component: AffiliateActivityPage,
        data: {
            breadcrumb: 'Affiliate Activity'
        },
    },
    {
        path: 'clicks',
        canActivate: [PermissionGuard],
        component: ClickPage,
        data: {
            breadcrumb: 'Clicks'
        },
    },
];

@NgModule({
  imports: [
    RouterModule.forChild(
        routes
    )
  ],
  exports: [
    RouterModule
  ]
})
export class ReportsRoutingModule {}
