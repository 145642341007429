import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    fetchTopCampaigns,
    fetchTopCampaignsSuccess,
    fetchTopCampaignsFailure,
    fetchTopPromotion,
    fetchTopPromotionSuccess,
    fetchTopPromotionFailure,
    fetchTopreferringUrls,
    fetchTopreferringUrlsSuccess,
    fetchTopreferringUrlsFailure,
    fetchBalanceSheet,
    fetchBalanceSheetSuccess,
    fetchBalanceSheetFailure,
    fetchChannels,
    fetchChannelsSuccess,
    fetchChannelsFailure,
    fetchGeneratedCommission,
    fetchGeneratedCommissionSuccess,
    fetchGeneratedCommissionFailure,
    fetchTopAffiliates,
    fetchTopAffiliatesSuccess,
    fetchTopAffiliatesFailure,
    fetchAffiliateActivity,
    fetchAffiliateActivitySuccess,
    fetchAffiliateActivityFailure,
    fetchClicks,
    fetchClicksSuccess,
    fetchClicksFailure
} from './report.actions';
import { of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { ReportService } from '@services/report/report.service';

@Injectable()
export class ReportEffects {

  loadTopCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchTopCampaigns),
      mergeMap(action =>
        this.reportService.getTopCampaigns(action.page, action.filterOptions).pipe(
          map(res => {
            return fetchTopCampaignsSuccess({ topCampaign: res });
          }),
          catchError(error => {
            return of(fetchTopCampaignsFailure({ error }));
          })
        )
      )
    )
  );

  loadTopPromotion$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fetchTopPromotion),
    mergeMap(action =>
      this.reportService.getTopPromotions(action.page, action.filterOptions).pipe(
        map(res => {
          return fetchTopPromotionSuccess({ topPromotion: res });
        }),
        catchError(error => {
          return of(fetchTopPromotionFailure({ error }));
        })
      )
    )
  )
);

  loadReferringUrl$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fetchTopreferringUrls),
    mergeMap(action =>
      this.reportService.getTopRefUrls(action.page, action.filterOptions).pipe(
        map(res => {
          return fetchTopreferringUrlsSuccess({ topRefUrls: res });
        }),
        catchError(error => {
          return of(fetchTopreferringUrlsFailure({ error }));
        })
      )
    )
  )
);

  loadBalanceSheet$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fetchBalanceSheet),
    mergeMap(action =>
      this.reportService.getBalanceSheet(action.page, action.filterOptions).pipe(
        map(res => {
          return fetchBalanceSheetSuccess({ balanceSheet: res });
        }),
        catchError(error => {
          return of(fetchBalanceSheetFailure({ error }));
        })
      )
    )
  )
);

  loadChannels$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fetchChannels),
    mergeMap(action =>
      this.reportService.getChannel(action.page, action.filterOptions).pipe(
        map(res => {
          return fetchChannelsSuccess({ channel: res });
        }),
        catchError(error => {
          return of(fetchChannelsFailure({ error }));
        })
      )
    )
  )
);

  loadGeneratedComm$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fetchGeneratedCommission),
    mergeMap(action =>
      this.reportService.getGeneratedComm(action.page, action.filterOptions).pipe(
        map(res => {
          return fetchGeneratedCommissionSuccess({ generatedComm: res });
        }),
        catchError(error => {
          return of(fetchGeneratedCommissionFailure({ error }));
      })
    )
  )
)
);

  loadTopAffiliate$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fetchTopAffiliates),
    mergeMap(action =>
      this.reportService.getTopAffiliates(action.page, action.filterOptions).pipe(
        map(res => {
          return fetchTopAffiliatesSuccess({ topAffiliate: res });
        }),
        catchError(error => {
          return of(fetchTopAffiliatesFailure({ error }));
      })
    )
  )
)
);

loadAffiliateActivity$ = createEffect(() =>
this.actions$.pipe(
  ofType(fetchAffiliateActivity),
  mergeMap(action =>
    this.reportService.getAffiliateAvtivity(action.page, action.filterOptions).pipe(
      map(res => {
        return fetchAffiliateActivitySuccess({ affActivity: res });
      }),
      catchError(error => {
        return of(fetchAffiliateActivityFailure({ error }));
      })
    )
  )
)
);

loadClicks$ = createEffect(() =>
this.actions$.pipe(
  ofType(fetchClicks),
  mergeMap(action =>
    this.reportService.getClicks(action.page, action.filterOptions).pipe(
      map(res => {
        return fetchClicksSuccess({ click: res });
      }),
      catchError(error => {
        return of(fetchClicksFailure({ error }));
      })
    )
  )
)
);

  constructor(
    private actions$: Actions,
    private reportService: ReportService
  ) {}

}
