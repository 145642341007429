import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasRoleDirective } from './has-role/has-role.directive';
import { ShowPasswordDirective } from './show-password/show-password.directive';
import {DropDownDirective} from "@directives/dropdown/dropdown.directive";

@NgModule({
  declarations: [HasRoleDirective, ShowPasswordDirective, DropDownDirective],
  exports: [
    HasRoleDirective,
    ShowPasswordDirective,
    DropDownDirective
  ],
  imports: [
    CommonModule
  ]
})
export class SharedDirectivesModule { }
