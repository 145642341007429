import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from 'ng2-ckeditor';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgSelectModule } from '@ng-select/ng-select';

import { BannersPageRoutingModule } from './banners-page-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '@shared/shared.module';

import { SharedPipesModule } from '@pipes/index';

import { BannersAddPage, BannersListPage, BannersListFilterComponent } from './index';
import { BannerTextLinkComponent } from './banner-text-link/banner-text-link.component';
import { BannerImageComponent } from './banner-image/banner-image.component';
import { BannerHtmlComponent } from './banner-html/banner-html.component';
import { BannerPromoEmailComponent } from './banner-promo-email/banner-promo-email.component';
import { BannerSimplePdfComponent } from './banner-simple-pdf/banner-simple-pdf.component';
import { SharedDirectivesModule } from '@directives/index';


@NgModule({
  declarations: [
    BannersAddPage,
    BannersListPage,
    BannersListFilterComponent,
    BannerTextLinkComponent,
    BannerImageComponent,
    BannerHtmlComponent,
    BannerPromoEmailComponent,
    BannerSimplePdfComponent,
  ],
  imports: [
    AngularEditorModule,
    BannersPageRoutingModule,
    CKEditorModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    SharedModule,
    NgSelectModule,
    SharedPipesModule,
    SharedDirectivesModule
  ]
})
export class BannersPageModule { }
