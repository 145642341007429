import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { HelperService, SharedService, BannerService } from '@services/index';
import * as ENUMS from "@enums/index";

@Component({
  selector: 'aff-banner-image',
  templateUrl: './banner-image.component.html',
  styleUrls: ['./banner-image.component.scss']
})
export class BannerImageComponent implements OnInit, OnDestroy {
  predefinedSizes = HelperService.enumToObject(ENUMS.BannerPredefineSize);
  wrappers = HelperService.enumToObject(ENUMS.WrapperForBanner);
  targetAttr = HelperService.enumToObject(ENUMS.TargetAttribut);

  // set values from parent component
  @Input() set bannersAddForm(options) {
    HelperService.fillFormValues(options, this.bannerImageForm)
  }
  @Input() htmlcode: any;
  @Output() private getObjValues = new EventEmitter<any>();
  @Output() private previewClicked = new EventEmitter<any>();
  
  public bannerImageForm: FormGroup;
  errorObj: any = {};
  imageId: string = '';
  statusType = ENUMS.StatusType;
  bannerSizeOption = ENUMS.BannerSizeOption;
  objectFit = ENUMS.ObjectFit;

  generatorValues;
  previewBanner: boolean = false;

  banner$: Observable<any>;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private bannerService: BannerService) {

    this.bannerImageForm = this.formBuilder.group({
      banner_size: new FormControl(this.bannerSizeOption.UNDEFINED, [Validators.required]),
      banner_wrapper: new FormControl(null, [Validators.required]),
      object_fit: new FormControl(this.objectFit.CONTAIN, [Validators.required]),
      width: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      height: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      banner_sizes: new FormControl(null, [Validators.required]),
      target_attr: new FormControl(null),
      img_alt_text: new FormControl(null, [Validators.required]),
      upload_file: new FormControl(null, [Validators.required])
    });

    this.banner$ = bannerService.getBannersSelector();
  }

  ngOnInit() {
    this.banner$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.imageId = state.selectedItem.image_url;
        this.bannerImageForm.patchValue({ upload_file: state.selectedItem.image_id })
      })

    // check click saved button
    this.bannerService.clickSaved$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(clicked => {
        if (clicked) {
          // check validations
          this.errorObj = HelperService.checkValidation(this.bannerImageForm);
        }
      });
  }

  onChangeFormValue(options) {
    console.log(options);
    console.log(this.bannerImageForm.value)
    
    switch (options.name) {
      case 'upload_file':
        if (options.value.length) {
          this.uploadImage(options.value);
          this.getObjValues.emit({ [options.name]: options.value });
        }
        break;

      case 'width':
      case 'height':
        this.bannerImageForm.patchValue({ [options.name]: parseInt(options.value) });
        this.getObjValues.emit({ [options.name]: parseInt(options.value) });
        break;

      default:
        this.bannerImageForm.patchValue({ [options.name]: options.value });
        this.getObjValues.emit({ [options.name]: options.value });
    }
  }

  uploadImage(files) {
    this.sharedService.onLoaderToggle(true);
    const imageFile: FormData = HelperService.createFormData(files); 

    this.sharedService.uploadImage(imageFile, 'promotion-tool')
      .then(res => {
        this.imageId = res['url'];
        this.bannerImageForm.patchValue({ upload_file: res['id'] });
        this.sharedService.onLoaderToggle(false);
        this.getObjValues.emit({ upload_file: res['id'] });
        this.generatorValues = res;
      })
      .catch(err => {
        this.sharedService.onLoaderToggle(false);
      })
  }

  deleteImage() {
    this.bannerImageForm.patchValue({ upload_file: null });
    this.getObjValues.emit({ upload_file: null });
    this.imageId = '';
  }

  previewImage(value) {
    if (this.bannerImageForm.value.upload_file) {
      this.previewBanner = value;
      this.previewClicked.emit(value);
    } else {
      this.previewBanner = false;
    }
  }

  closeImgPopup(value) {
    this.previewBanner = value;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
