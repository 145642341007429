import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageService } from '@services/page/page.service';
import { IPage, IFormOptions } from '@interfaces/index';
import { Observable, Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HelperService } from '@services/helper/helper.service';
import { IModalvalue } from '@shared-models/index';
import { AffiliateManagersService } from '../../../services/affiliate-managers/affiliate-managers.service';
import { EventCategoryService, UserService } from '@services/index';
import { IState } from "@models/index";
import * as ENUMS from "@enums/index";

@Component({
  selector: 'aff-event-categories',
  templateUrl: './event-categories.component.html',
  styleUrls: ['./event-categories.component.scss']
})
export class EventCategoriesPage implements OnInit, OnDestroy {

  addEventCategoryForm: FormGroup;
  editMode: boolean = false;

  categoryId: string;

  confirmationModal: boolean = false;
  deleteValues: string = '';

  showFiterComponent: boolean = false;
  popupForm: boolean = false;
  loadingIndicator: boolean = true;

  selectedRows = [];
  rows: any[] = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  errorObj: any = {};
  userID: number;

  managers$: Observable<IState>;
  eventCategories$: Observable<any>;
  eventCategory$: Observable<any>;
  profile$: Observable<any>;

  selectedValues: any = {
    managers: []
  };
  lists: any = {
    managers: []
  };
  searchValues: any = {
    managers: new FormControl(),
    keyword: new FormControl()
  };


  unsubscribe$ = new Subject<void>();

  constructor(
    private eventCategoryService: EventCategoryService,
    private pageService: PageService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private affiliateManagersService: AffiliateManagersService) {

    this.addEventCategoryForm = this.formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      user_id: new FormControl(null, [Validators.required])
    });
    this.pageService.changePageInfo({ breadcrum: ['Tracking', 'Event Categories'] });

    this.eventCategories$ = this.eventCategoryService.getEventCategoriesSelector();
    this.eventCategory$ = this.eventCategoryService.getEventCategorySelector();
    this.profile$ = this.userService.getProfileSelector();
    this.eventCategoryService.fetchEventCategories(1, {});

     // get affiliate managers
     this.managers$ = this.affiliateManagersService.getAffiliateManagersSelector();
     this.affiliateManagersService.fetchAffiliateManagers(1, {});

  }

  ngOnInit(): void {

    this.profile$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        if (!HelperService.isObjectEmpty(user) && 
            user.roles.length && 
            user.roles[0].role_id === ENUMS.UserRole.MANAGER) {
            // this.addEventCategoryForm.patchValue({ user_id: parseInt(user.userId) });
            this.userID = parseInt(user.userId);
        }
      }) 

    this.eventCategories$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {  
        if(!HelperService.isObjectEmpty(state.selectedItem)) {
          this.addEventCategoryForm.patchValue({
            user_id: state.selectedItem.user.user_id,
            name: state.selectedItem.name,
          });
            this.selectedValues.managers = state.selectedItem.user; 
        }
        if (!state.isLoaded) return;
        this.rows = [];
        for (const eventCategory of state.data) {          
          const row = {
            eventCategoryId: eventCategory.id,
            eventCategoryName: eventCategory.name
          };
          this.rows.push(row);
        }

        this.rows = [...this.rows];

        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
      });
    

       // affiliate managers search
       this.searchValues.managers.valueChanges
       .pipe(
         takeUntil(this.unsubscribe$),
         debounceTime(500),
         distinctUntilChanged()
       )
       .subscribe(keyword => this.affiliateManagersService.fetchAffiliateManagers(1, { keyword }));
 
     // get affiliate managers list
     this.getManagers();

  }

  getManagers(): void {
    this.managers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.lists.managers = state.data;
        }
      });
  }

  getSearchValue(options, name: string): void {
    // search value in managers
    this.searchValues[name].patchValue(options.term);
  }

  onSelectedValue(values: any[], name: string): void {
    switch(name) {
      case 'managers':
          this.addEventCategoryForm.patchValue({user_id: values['id']});
        break;
  }
}


  onAddEventCategory(): void {
    this.addEventCategoryForm.reset();
    delete this.errorObj['name'];
    delete this.errorObj['user_id'];
    this.selectedValues = {
      managers: []
    };
    this.editMode = false;
    this.popupForm = true;
  }

  closePopup(value: boolean): void {
    this.popupForm = value;
  }

  onAction(action: string, row: any): void {    
     switch (action) {
      case "delete":
        this.deleteValues = `Event Category: <br><br> ${row.eventCategoryName}`;
        this.selectedRows= [{ id: row.eventCategoryId }];
        this.confirmationModal = true;
        break;

      case "edit":
        delete this.errorObj['name'];
        this.popupForm = true;
        this.editMode = true;
        this.categoryId = row.eventCategoryId;
        this.eventCategoryService.fetchEventCategory(row.eventCategoryId);
        break;
    }
  }

  onDeleteConfirm(options: IModalvalue): void {
    if (options.confirm) {
      for (const selectedRow of this.selectedRows) {
        this.eventCategoryService.deleteEventCategory(selectedRow.id);
      }
      this.selectedRows = [];
    }
    this.confirmationModal = false;
  }

  onSelect(e): void {
    this.selectedRows = e.selected;
  }

  saveEventType(): void {
    this.errorObj = HelperService.checkValidation(this.addEventCategoryForm);

    if(this.userID) {
      this.addEventCategoryForm.value.user_id = this.userID;
      delete this.errorObj['user_id'];
    }
    if (HelperService.isObjectEmpty(this.errorObj)) {
      this.eventCategoryService.addEventCategory(this.addEventCategoryForm.value);  
    }
  }

  updateEventType() {
    if(this.userID) {
      delete this.errorObj['user_id'];
    }
    this.errorObj = HelperService.checkValidation(this.addEventCategoryForm);
    if (HelperService.isObjectEmpty(this.errorObj)) {
      this.eventCategoryService.updateEventCategory({ id: this.categoryId, ...this.addEventCategoryForm.value });
    }
  }

  onChangeFormValue(options: IFormOptions): void {
    
    switch(options.name) {
      case 'submit' :
        this.editMode ? this.updateEventType() : this.saveEventType();
        if (HelperService.isObjectEmpty(this.errorObj)) {
        this.popupForm = false;
        }
        break;
      case 'user_id' :
        this.addEventCategoryForm.patchValue({ [options.name]: parseInt(options.value) });
        break;
      default :
        this.addEventCategoryForm.patchValue({ [options.name]: options.value });
        break;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
