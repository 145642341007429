import { createAction, props } from "@ngrx/store";


export const fetchEventTypes = createAction(
  "[Event Type] Fetch Event Type",
  props<{ page, filterOptions }>()
);

export const fetchEventTypesSuccess = createAction(
  "[Event Type] Fetch Event Type Success",
  props<{ eventType }>()
);

export const fetchEventTypesFailure = createAction(
  "[Event Type] Fetch Event Type Faild",
  props<{ error }>()
);

export const getEventType = createAction(
  "[Event Type] Get Event Type",
  props<{ id }>()
);

export const getEventTypeSuccess = createAction(
  "[Event Type] Get Event Type Success",
  props<{ eventType }>()
);

export const getEventTypeFailure = createAction(
  "[Event Type] Get Event Type Failure",
  props<{ error }>()
)

export const addEventType = createAction(
  "[Event Type] Add Event Type",
  props<{ eventType }>()
);

export const addEventTypeSuccess = createAction(
  "[Event Type] Add Event Type Success",
  props<{ eventType }>()
);

export const addEventTypeFailure = createAction(
  "[Event Type] Add Event Type Failure",
  props<{ error }>()
);

export const updateEventType = createAction(
  "[Event Type] Update Event Type",
  props<{ eventType }>()
);

export const updateEventTypeSuccess = createAction(
  "[Event Type] Update Event Type Success",
  props<{ eventType }>()
);

export const updateEventTypeFailure = createAction(
  "[Event Type] Update Event Type Failure",
  props<{ error }>()
);

export const deleteEventType = createAction(
  "[Event Type] Delete Event Type",
  props<{ id }>()
);

export const deleteEventTypeSuccess = createAction(
  "[Event Type] Delete Event Type Success",
  props<{ id }>()
);

export const deleteEventTypeFailure = createAction(
  "[Event Type] Delete Event Type Failure",
  props<{ error }>()
);

export const fetchClick = createAction(
  "[Event Type] Get Click",
  props<{ page, id }>()
);

export const fetchClickSuccess = createAction(
  "[Event Type] Get Click Success",
  props<{ click }>()
);

export const fetchClickFailure = createAction(
  "[Event Type] Get Click Failure",
  props<{ error }>()
)




