import { Injectable } from "@angular/core";
import { FormGroup, AbstractControl } from '@angular/forms';
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class HelperService {

  constructor() {}

  public static generateQueryParams(queryparams: any = { }) {
    const queryparamsArray = [];
    for(let key in queryparams){
        if((Array.isArray(queryparams[key]) && !queryparams[key].length) || !queryparams[key]){
        continue;
        }
        let param = key + '=' + (Array.isArray(queryparams[key])? queryparams[key].join(',') : queryparams[key]);
        queryparamsArray.push(param);
    }

    const queryString = queryparamsArray.join('&');

    return queryString;
  }

  // Turn enum into object
  public static enumToObject(enumme: any): any {
    const enumObj = {};
    Object.keys(enumme)
      .forEach(key => enumObj[enumme[key]] = key);

    return enumObj;
  }

  //Turn enum into array
  public static enumToArray(enumme: any){
    const enumArray = [];
    Object.keys(enumme).forEach(key => enumArray.push(enumme.key))
    return enumArray;
  }

  public static numberEnumToObject(enumme: any): any {
    let enumToObj = {};
    Object.keys(enumme).forEach(key => {
      if (isNaN(Number(key)) === false) {
        enumToObj[key] = enumme[key];
      }
    });

    return enumToObj;
  }

  public static fillFormValues(values: any, formGroup: FormGroup) {
    Object.keys(values).forEach(key => {
      formGroup.patchValue({ [key]: values[key] });
    });
  }

  public static fillFilterOption(getFilter: any, myFilter: any) {
    if (!getFilter) return;
    Object.keys(getFilter).forEach(key => {
      const getFilterObj = getFilter[key];
      Array.isArray(myFilter[getFilterObj.key]) ? myFilter[getFilterObj.key].push(getFilterObj.value)
                                                : myFilter[getFilterObj.key] = getFilterObj.value;
    });
  }

  public static onError(option: any) {
    const key = Object.keys(option)[0];

    let error = {
      required : 'Field is a required!',
      '^[0-9]*$' : 'Field must be number!',
      "^[0-9a-zA-Z]+$": 'Use only letters and numbers!',
      '^[a-zA-Z]*$' : 'Field must contain only letters!',
      email: `Email format is not correct!`,
      maxlength : `Maximum ${option[key].requiredLength} letters!`,
      minlength : `Minimum ${option[key].requiredLength} letters!`,
      UrlFormatIncorrect : 'URL format is not correct!',
      NoPassswordMatch : 'Password Does not mutch!',
      '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$' : 'Email format is not correct!'
    }

    if (key == 'pattern') {
      return error[option[key].requiredPattern]
    }

    return error[key];
  }

  public static checkValidation(form: FormGroup) {
    let errorObj = {};
    if (this.isObjectEmpty(form)) return errorObj;
    Object.keys(form.value).forEach(key => {
      if (form.controls[key].errors) {
        errorObj[key] = this.onError(form.controls[key].errors);
      }
    });
    return errorObj;
  };

  public static copyImgData(value) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  public static scrollOnErrorElement(errorObj: any): void {
    if (this.isObjectEmpty(errorObj)) return errorObj;

    const htmlElement: HTMLElement = document.querySelector(`[name=${Object.keys(errorObj)[0]}]`);
    if (htmlElement) {
      htmlElement.scrollIntoView({behavior: "smooth", block:"center"});
    }
  }

  public static isObjectEmpty(object: any): boolean {
    if (!object) return true;
    return !!(Object.entries(object).length === 0 && object.constructor);
  }

  public static passwordMatchValidator(control: AbstractControl): void {
    const password: string = control.get('password').value; // get password from our password form control
    const confirmPassword: string = control.get('confirm_password').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('confirm_password').setErrors({ NoPassswordMatch: true });
    }
  }

  public static urlValidator(controlName: string,) {
    return (formGroup: FormGroup) => {
      const url = formGroup.value[controlName];
      const pattern = /^https?:\/\/.+\..+$/;

      if (url && !pattern.test(url)) {
        formGroup.get(controlName).setErrors({ UrlFormatIncorrect: true });;
      }
    }
  }

  public static createFormData(files: FileList): FormData {
    const fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append('image', fileToUpload, fileToUpload.name);
    return formData;
  }

  public static formattedDateFormater(date: any): string {
    if (!date) return null;
    return new Date(date).toISOString().slice(0, 10);
  }

  public static dateFormater(date: any): string {
    if (!date) return null;
    const formatedDate = new Date(date)
    const year = formatedDate.getFullYear(),
        month = formatedDate.getMonth() + 1, // months are zero indexed
        day = formatedDate.getDate(),
        hour = formatedDate.getHours(),
        minute = formatedDate.getMinutes(),
        second = formatedDate.getSeconds()

    return year + "-" + month + "-" + day + " " + hour + ":" +
            minute +":" + second;
  }

  public static getEnumValue(en: any, value: any): string {
    if (!en || this.isObjectEmpty(en) || !value) {
      return '';
    }

    const val = Object.keys(en).find(k => en[k] === value);
    return val;
  }

  public static checkEmptyFilter(filter: any): boolean {
    if (!filter) return false;
    let isEmpty = true;
    for (const key of Object.keys(filter)) {
      if ((!Array.isArray(filter[key]) && filter[key]) || (Array.isArray(filter[key]) && filter[key].length)) {
        isEmpty = false;
      }
    }
    return isEmpty;
  }

  public static goToLink(link: string): void {
    window.open(link, '_blank');
  }

  numberToBool(type: boolean | string) {
    if (typeof type === 'string') {
      return !!parseFloat(type);
    }
    return type;
  }

  public static removeDups(names) {
    const unique = {};

    names.forEach((i) =>  {
      if (!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  }

  public static calcAmount(dataArray, type?) {
    return dataArray.reduce((total, amount) => {
      total += parseFloat(amount.sum);

      return total;
    }, 0);
  };


  public static strParse(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  }

  public static firstUppercase(str) {
    return str.charAt(0) === '0' ? str.substr(1) : str;
  }

  public static calendarDateGenerate(str) {
    return {
      year: str.split('-')[0],
      month: str.split('-')[1].substr(1),
      day: str.split('-')[2].substr(1)
    };
  }

  public static getURLQuery(search) {
    return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) });
  }

  public static generateQueryParamsObj(obj) {
    const optionsPass = {};

    for (let opt in obj) {
      if (obj.hasOwnProperty(opt)) {
        if (obj[opt].length || typeof obj[opt] == "boolean" ? obj[opt] : obj[opt].length) {
          optionsPass[opt] = [obj[opt].toString()];
        }
      }
    }

    return {...optionsPass};
  }

  public static queryParamsToObj(obj, target) {
    const optionsPass = {};
    const targetEl = {...target};

    for (let option in obj) {
      if (obj.hasOwnProperty(option)) {
        const el = obj[option].toString();
        if (el.split(',').length > 1) {
          optionsPass[option] = el.split(',').map(i => HelperService.strParse(i));
        } else if (el.split('-').length > 1) {
          optionsPass[option] = [el];
        } else {
          optionsPass[option] = [HelperService.strParse(el)];
        }
      }
    }

    for (let option in targetEl) {
      if (targetEl.hasOwnProperty(option)) {
        if (targetEl[option] && !optionsPass[option]) {
          optionsPass[option] = typeof targetEl[option] === "object" ? [] : '';
        }
      }
    }

    return {...targetEl, ...optionsPass};
  }

  public static getKeyOf(obj, str) {
    let retkey = null;
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key].toLowerCase() === str.toLowerCase()) {
          retkey = key;
        }
      }
    }

    return retkey;
  }
}
