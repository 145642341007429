import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {
    ListComponent,
    LineChartComponent,
    FilterComponent,
    ColumnChartComponent,
    CardComponent,
    BlockComponent,
    ButtonComponent,
    CheckboxComponent,
    InputComponent,
    SelectSingleComponent,
    SelectMultyComponent,
    TextAreaComponent,
    PopupComponent,
    RadioComponent,
    NotificationAlertComponent,
    CustomSelectComponent,
    ModalComponent,
    ImgPopupComponent,
    HelpQuestionMarkComponent
} from './index';

/**
 * Array of shared components
 */
const SHARED_MODULES = [
    ListComponent,
    LineChartComponent,
    FilterComponent,
    ColumnChartComponent,
    CardComponent,
    BlockComponent,
    ButtonComponent,
    CheckboxComponent,
    InputComponent,
    SelectSingleComponent,
    SelectMultyComponent,
    TextAreaComponent,
    PopupComponent,
    RadioComponent,
    NotificationAlertComponent,
    CustomSelectComponent,
    ModalComponent,
    ImgPopupComponent,
    HelpQuestionMarkComponent
]

@NgModule({
  declarations: [
    SHARED_MODULES
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    SHARED_MODULES
  ]
})

/**
 * Module for shared components
 */
export class SharedModule { }
