import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { IPage } from '@interfaces/index';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { PaymentMethodsService, ConfigService, AffiliateService, HelperService } from '@services/index';
import { IState } from "@models/index";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'aff-affiliates-payouts-page',
  templateUrl: './affiliates-payouts-page.component.html',
  styleUrls: ['./affiliates-payouts-page.component.scss']
})
export class AffiliatesPayoutsPage implements OnInit, OnDestroy {

  affiliatePayoutsForm: FormGroup;
  affiliateID: string = '';

  paymentMethods$: Observable<IState>;
  affiliate$: Observable<any>;
  affiliate: any = {};

  userId;

  errorObj: any = {};

  paymentMethods: any;

  lists: any = {
    paymentMethods: []
  };

  treshold$: Observable<any>;
  treshold;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private paymentMethodsService: PaymentMethodsService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private affiliateService: AffiliateService,
  ) {
    this.affiliatePayoutsForm = this.formBuilder.group({
      payment_method_id: new FormControl(null, [Validators.required]), 
      min_payout: new FormControl(null),
      payment_requisite: new FormControl(null, [Validators.required])
    });

    this.paymentMethods$ = this.paymentMethodsService.getPaymentMethodsSelector();
    this.treshold$ = this.configService.getconfigsSelector();
    this.affiliate$ = this.affiliateService.getAffiliateSelector();

    this.paymentMethodsService.fetchPaymentMethods(1, {});
    this.configService.fetchTreshold();
    this.affiliateService.fetchFilteredAffiliates( 1, {})
   
  }

  ngOnInit() { 

    // get affiliate id
    this.affiliateID = this.route.snapshot.params.id;

    if (this.affiliateID) {
      this.affiliateService.fetchAffiliate(this.affiliateID);
    }

    this.affiliate$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(affiliate => {
      // check mode
      if (!HelperService.isObjectEmpty(affiliate)) {
        this.affiliate = affiliate;
        this.userId = affiliate.id;

        // this.affiliatePayoutsForm.value.owner= affiliate.payment_method_id;
        this.paymentMethods = affiliate.payment_method;
        // this.affiliatePayoutsForm.value.payment_requisite = affiliate.payment_requisite;
        
      }
      HelperService.fillFormValues(affiliate, this.affiliatePayoutsForm);

    });
        

    this.treshold$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      for (const treshold of state.data) {
        this.treshold = treshold.value;
      }
    });

    this.paymentMethods$
      .pipe(takeUntil(this.unsubscribe$)) 
      .subscribe(state => {
        if (state.isLoaded) {
          this.lists.paymentMethods = state.data;
        }
      });

  }

  onSelectedValue(payMethod): void {
    this.affiliatePayoutsForm.patchValue({ payment_method_id: payMethod ? payMethod.id : null });
  }

  saveAffiliatePayMethod() {
    let affPayoutObj = {userId: this.userId, id: this.affiliatePayoutsForm.value.payment_method_id, requisite: this.affiliatePayoutsForm.value.payment_requisite};
     
    this.errorObj = HelperService.checkValidation(this.affiliatePayoutsForm);
    if (HelperService.isObjectEmpty(this.errorObj)) {
        this.paymentMethodsService.updateUserPayout({userId: this.userId, id: this.affiliatePayoutsForm.value.payment_method_id, requisite: this.affiliatePayoutsForm.value.payment_requisite});
    }
  }

  onChangeFormValue(options) {
    switch(options.name) {
      case 'save':
        this.saveAffiliatePayMethod();
        break;
      default:
        this.affiliatePayoutsForm.patchValue({ [options.name]: options.value });
        break;
    }
    
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
