import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';

/**
 * Dynamic Textarea Component
 */
@Component({
  selector: 'aff-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements OnInit {
  @ViewChild('textAreaElement', { static: true }) textAreaElement: ElementRef;
  greenBorder: boolean = false;
  /**
   *
   * @param att
   */
  @Input() set options(att) {
    Object.keys(att).forEach(key => {
      this.textAreaElement.nativeElement.setAttribute(key, att[key])
    });
  }

  @Input() disabled: boolean = false;
  @Input() value = '';
  @Input() class = '';
  @Input() error: string = '';
  @Output() onOutPutValue = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  /**
   *
   * @param inputValue
   */
  onChange(inputValue) {
    const options = {
      value: inputValue.target.value,
      name: this.textAreaElement.nativeElement.name
    };
    this.onOutPutValue.emit(options);

    if(inputValue.target.value.length) {
      this.greenBorder = true;
  } else {
    this.greenBorder = false;
  }
}

}
