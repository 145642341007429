import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageService } from '@services/page/page.service';
import { IPage, IFormOptions } from '@interfaces/index';
import { Observable, Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HelperService } from '@services/helper/helper.service';
import { IModalvalue } from '@shared-models/index';
import { TrackingService, UserService, CampaignService, BannerService, EventCategoryService, AffiliateManagersService } from '@services/index';
import { IState } from "@models/index";
import * as ENUMS from "@enums/index";

@Component({
  selector: 'aff-event-types',
  templateUrl: './event-types.component.html',
  styleUrls: ['./event-types.component.scss']
})
export class EventTypesPage implements OnInit, OnDestroy {

  addEventTypeForm: FormGroup;
  editMode: boolean = false;

  disabledCampaigns: boolean = true;
  disabledBanners: boolean = true;
  disabledCategories: boolean = true;

  typeId: string;

  confirmationModal: boolean = false;
  deleteValues: string = '';

  showFiterComponent: boolean = false;
  popupForm: boolean = false;
  loadingIndicator: boolean = true;

  selectedRows = [];
  rows: any[] = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  errorObj: any = {};
  userID: number;

  eventTypes$: Observable<any>;
  eventType$: Observable<any>;
  profile$: Observable<any>;

  managers$: Observable<IState>;
  campaigns$: Observable<IState>;
  banners$: Observable<IState>;
  categories$: Observable<IState>;

  selectedValues: any = {
    campaigns: [],
    banners: [],
    categories: [],
    managers: []
  };
  lists: any = {
    campaigns: [],
    banners: [],
    categories: [],
    managers: []
  };
  searchValues: any = {
    campaigns: new FormControl(),
    banners: new FormControl(),
    categories: new FormControl(),
    managers: new FormControl(),
    keyword: new FormControl()
  };

  unsubscribe$ = new Subject<void>();

  constructor(
    private trackingService: TrackingService,
    private pageService: PageService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private bannerService: BannerService,
    private campaignService: CampaignService,
    private eventCategoryService: EventCategoryService,
    private affiliateManagersService: AffiliateManagersService) {

    this.addEventTypeForm = this.formBuilder.group({
      event_name: new FormControl(null, [Validators.required]),
      user_id: new FormControl(null),
      category_id: new FormControl(null),
      campaigns: new FormControl(null),
      banners: new FormControl(null),
    });

    this.pageService.changePageInfo({ breadcrum: ['Tracking', 'Event Types'] });

    this.eventTypes$ = this.trackingService.getEventTypesSelector();
    this.eventType$ = this.trackingService.getEventTypeSelector();
    this.profile$ = this.userService.getProfileSelector();
    this.trackingService.fetchEventTypes(1, {});

    // get campaigns
    this.campaigns$ = this.campaignService.getCampaignsSelector();
    this.campaignService.fetchCampaigns(1, {});
    // get promotions
    this.banners$ = this.bannerService.getBannersSelector();
    this.bannerService.fetchBanners(1, {});
    //get categories
    this.categories$ = this.eventCategoryService.getEventCategoriesSelector();
    this.eventCategoryService.fetchEventCategories(1, {});
    // get affiliate managers
    this.managers$ = this.affiliateManagersService.getAffiliateManagersSelector();
    this.affiliateManagersService.fetchAffiliateManagers(1, {});

  }

  ngOnInit(): void {

    this.profile$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        if (!HelperService.isObjectEmpty(user) &&
          user.roles.length &&
          user.roles[0].role_id === ENUMS.UserRole.MANAGER) {
          this.addEventTypeForm.patchValue({ user_id: parseInt(user.userId) });
          this.userID = parseInt(user.userId);
        }
      })

    this.eventTypes$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (!HelperService.isObjectEmpty(state.selectedItem)) {
          const campaigns: number[] = [];
          state.selectedItem.campaigns.forEach(camp => {
            campaigns.push(camp.campaign_id);
          });
          const banners: number[] = [];
          state.selectedItem.banners.forEach(bann => {
            banners.push(bann.banner_id);
          });

          this.addEventTypeForm.patchValue({
            user_id: state.selectedItem.user.user_id,
            category_id: state.selectedItem.category.category_id,
            event_name: state.selectedItem.event_name,
            campaigns,
            banners
          });

          this.selectedValues.campaigns = state.selectedItem.campaigns;
          this.selectedValues.banners = state.selectedItem.banners;
          this.selectedValues.managers = state.selectedItem.user;
          this.selectedValues.categories = state.selectedItem.category;
        }
        if (!state.isLoaded) return;

        this.rows = [];
        for (const eventType of state.data) {
          const row = {
            eventTypeId: eventType.event_type_id,
            eventType: eventType.event_type_name,
            categoryName: eventType.name ? eventType.name : ''
          };
          this.rows.push(row);
        }

        this.rows = [...this.rows];

        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
      });

    this.searchValues.campaigns.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.campaignService.fetchCampaigns(1, { keyword }));

    // get campaigns 
    this.getCampaigns();

    // promotional tool search
    this.searchValues.banners.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.bannerService.fetchBanners(1, { keyword }));

    // get promotional tool
    this.getBanners();

    // event categories search
    this.searchValues.categories.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.eventCategoryService.fetchEventCategories(1, { keyword }));

    // get event categories
    this.getEventCategories();

    // affiliate managers search
    this.searchValues.managers.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.affiliateManagersService.fetchAffiliateManagers(1, { keyword }));

    // get affiliate managers list
    this.getManagers();

  }

  getCampaigns(): void {
    this.campaigns$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.lists.campaigns = state.data;
        }
      });
  }

  getBanners(): void {
    this.banners$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.lists.banners = state.data;
        }
      });
  }

  getEventCategories(): void {
    this.categories$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.lists.categories = state.data;
        }
      });
  }

  getManagers(): void {
    this.managers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.lists.managers = state.data;
        }
      });
  }

  onAddEventType(): void {
    this.addEventTypeForm.reset();
    delete this.errorObj['event_name'];
    delete this.errorObj['user_id'];
    this.disabledCampaigns = true;
    this.disabledBanners = true;
    this.disabledCategories = true;
    if(this.userID) {
      this.disabledCampaigns = false;
      this.disabledCategories = false;
    }
    
    this.selectedValues = {
      campaigns: [],
      banners: [],
      categories: [],
      managers: []
    };
    this.editMode = false;
    this.popupForm = true;
  }

  closePopup(value: boolean): void {
    this.popupForm = value;
  }

  onAction(action: string, row: any): void {    
    switch (action) {
      case "delete":
        this.deleteValues = `Event Type: <br><br> ${row.eventType}`;
        this.selectedRows = [{ id: row.eventTypeId }];
        this.confirmationModal = true;
        break;

      case "edit":
        this.disabledCampaigns = false;
        this.disabledBanners = false;
        this.disabledCategories = false;

        this.popupForm = true;
        this.editMode = true;
        this.typeId = row.eventTypeId;
        this.trackingService.fetchEventType(row.eventTypeId);
        break;
    }
  }

  onDeleteConfirm(options: IModalvalue): void {
    if (options.confirm) {
      for (const selectedRow of this.selectedRows) {
        this.trackingService.deleteEventType(selectedRow.id);
      }
      this.selectedRows = [];
    }
    this.confirmationModal = false;
  }

  onSelect(e): void {
    this.selectedRows = e.selected;
  }

  saveEventType(): void {
    this.errorObj = HelperService.checkValidation(this.addEventTypeForm);

    if (this.userID) {
      this.addEventTypeForm.value.user_id = this.userID;
      delete this.errorObj['user_id'];
    }

    console.log(this.addEventTypeForm.value);
    console.log(this.errorObj);

    if (HelperService.isObjectEmpty(this.errorObj)) {
      console.log('daemata');
      
      this.trackingService.addEventType(this.addEventTypeForm.value);
    }
  }

  updateEventType() {

    if (this.userID) {
      delete this.errorObj['user_id'];
    }
    this.errorObj = HelperService.checkValidation(this.addEventTypeForm);
    if (this.addEventTypeForm.value.event_name != '') {
      delete this.errorObj['event_name'];
    }
    if (HelperService.isObjectEmpty(this.errorObj)) {
      this.trackingService.updateEventType({ id: this.typeId, ...this.addEventTypeForm.value });
    }
  }

  getSearchValue(options, name: string): void {
    this.searchValues[name].patchValue(options.term);
  }

  onSelectedValue(values: any[], name: string): void {
    console.log(this.userID);
    
    if(!this.userID){
    if(this.selectedValues.managers && Object.keys(this.selectedValues.managers).length) {
      this.disabledCampaigns = false;
      this.disabledCategories = false;
    } else {
      this.disabledCampaigns = true;
      this.disabledCategories = true;
    }

    if( !this.disabledCampaigns && 
        this.selectedValues.campaigns && Object.keys(this.selectedValues.campaigns).length ) {
          this.disabledBanners = false;
        } else {
          this.disabledBanners = true;
    }

  } else if(this.userID && this.selectedValues.campaigns && Object.keys(this.selectedValues.campaigns).length) {
      this.disabledBanners = false;
  } else {
    this.disabledBanners = true;
  }

    switch (name) {
      case 'managers':
        this.selectedValues = {
          ...this.selectedValues,
          campaigns: [],
          banners: [],
          categories: []
        };

        this.addEventTypeForm.patchValue({ user_id: values ? values['id'] : null });
        if (values) {
          this.campaignService.fetchCampaigns(1, { managers: values['id'] });
          this.eventCategoryService.fetchEventCategories(1, { user_id: values['id'] });
          this.campaigns$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(state => {
              if (state.isLoaded) {
                this.lists.campaigns = state.data;
              }
            });
        }

        break;

      case 'categories':
        this.addEventTypeForm.patchValue({ category_id: values ? values['id'] : null })
        break;

      case 'campaigns':
        this.selectedValues = {
          ...this.selectedValues,
          banners: []
        };
        let selectedValue: number[] = [];
        for (const value of values) {
          selectedValue.push(value.id);
        }
        this.addEventTypeForm.value.campaigns = selectedValue;
        this.addEventTypeForm.patchValue({ campaigns: selectedValue });
        let campIds = selectedValue.join(',');
        this.bannerService.fetchBanners(1, { campaigns: campIds });
        this.banners$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(state => {
            if (state.isLoaded) {
              this.lists.banners = state.data;
            }
          });
        break;

      case 'banners':
        let selectedValues: number[] = [];
        for (const value of values) {
          selectedValues.push(value.id);
        }
        this.addEventTypeForm.value.banners = selectedValues;
        this.addEventTypeForm.patchValue({ banners: selectedValues });

        break;
    }
  }

  onChangeFormValue(options: IFormOptions): void {
    switch (options.name) {
      case 'submit':
        this.editMode ? this.updateEventType() : this.saveEventType();
        if (HelperService.isObjectEmpty(this.errorObj)) {
          this.popupForm = false;
        }
        break;
      case 'user_id':
        this.addEventTypeForm.patchValue({ [options.name]: parseInt(options.value) });
        break;
      default:
        this.addEventTypeForm.patchValue({ [options.name]: options.value });
        break;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
