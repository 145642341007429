import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { BannerService, HelperService, SharedService} from '@services/index';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import * as ENUMS from "@enums/index";

@Component({
  selector: 'aff-banner-html',
  templateUrl: './banner-html.component.html',
  styleUrls: ['./banner-html.component.scss']
})
export class BannerHtmlComponent implements OnInit, OnDestroy {

  bannerHtmlForm:FormGroup;

   // set values from parent component
  @Input() set bannersAddForm(options) {
    HelperService.fillFormValues(options, this.bannerHtmlForm);
  }
  
  @Input() htmlcode;
  @Output() private getObjValues = new EventEmitter<any>();
  @Output() private previewClicked = new EventEmitter<any>();

  generatorValues;  

  bannerSizeOption = ENUMS.BannerSizeOption;
  objectFit = ENUMS.ObjectFit;

  errorObj: any = {};
  private unsubscribe$ = new Subject<void>();

  windowMode = HelperService.enumToObject(ENUMS.WindowMode);
  predefinedSizes = HelperService.enumToObject(ENUMS.BannerPredefineSize);
  wrappers = HelperService.enumToObject(ENUMS.WrapperForBanner);
  targetAttr = HelperService.enumToObject(ENUMS.TargetAttribut);
  variables: string[] = [''];

  public imageGenerator: boolean = false;
  previewBanner: boolean = false;

  imageId: string = '';

  constructor(
    private bannerService: BannerService, 
    private sharedService: SharedService,
    private formBuilder: FormBuilder ) { 
    this.bannerHtmlForm = this.formBuilder.group({
      banner_size: new FormControl(this.bannerSizeOption.UNDEFINED, [Validators.required]),
      object_fit: new FormControl(this.objectFit.CONTAIN, [Validators.required]),
      width: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]), 
      height: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]), 
      banner_sizes: new FormControl(null, [Validators.required]),
      banner_wrapper: new FormControl(null, [Validators.required]),
      html_code: new FormControl(null, [Validators.required]),
      upload_file: new FormControl(null, [Validators.required])
    });
  }

  ngOnInit() {
       // check click saved button
       this.bannerService.clickSaved$
       .pipe(takeUntil(this.unsubscribe$))
       .subscribe(clicked => {
         if (clicked) {
           // check validations
           this.errorObj = HelperService.checkValidation(this.bannerHtmlForm);
         }
       });

  }

  onChangeFormValue(options) {
    switch(options.name) {
      case 'cancel' :
        break;

      case 'submit' :
        break;
      case 'upload_file':
        if(options.value.length) {
          this.uploadImage(options.value);
          this.getObjValues.emit({ [options.name]: options.value });
        }
        break;
      
      case 'width':
      case 'height':
        this.bannerHtmlForm.patchValue({ [options.name]: parseInt(options.value)});
        this.getObjValues.emit({ [options.name]: parseInt(options.value) });
        break;

      default :
      this.bannerHtmlForm.patchValue({ [options.name]: options.value });
      this.getObjValues.emit({ [options.name]: options.value });
    }
  }

  
  uploadImage(files) {
    this.sharedService.onLoaderToggle(true);
    const imageFile: FormData = HelperService.createFormData(files); 
    
    this.sharedService.uploadImage(imageFile, 'promotion-tool')
      .then(res => {
        this.imageId = res['url'];
        this.bannerHtmlForm.patchValue({ upload_file: res['id'] });
        this.sharedService.onLoaderToggle(false);
        this.getObjValues.emit({upload_file: res['id'] });

        this.imageGenerator = true;
        
        this.generatorValues = res;
      })
      .catch(err => {
        this.sharedService.onLoaderToggle(false);
        this.imageGenerator = false;
      })
      
  }

 deleteImage() {
    this.bannerHtmlForm.patchValue({ upload_file: null });
    this.getObjValues.emit({ upload_file: null });
    this.imageId = '';
  }

  popupToggle(value) {
    this.imageGenerator = value;
  }

  previewImage(value) {
    console.log(this.bannerHtmlForm.value.upload_file);
    
    if(this.bannerHtmlForm.value.html_code) {
     this.previewBanner = value;
     this.previewClicked.emit(value);
    } else {
     this.previewBanner = false;
    }
  }

  closeImgPopup(value) {
    this.previewBanner = value;
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  
}
